/** @jsx jsx */
import { jsx } from "theme-ui";
import { graphql } from "gatsby";
import { PortableText } from "@portabletext/react";
import Layout from "../components/layout";
import Container from "../components/container";
import PortableTextComponent from "../components/serializers/portableTextComponents";
import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import ArticleRelatedContent from "../components/entities/article/articleRelatedContent";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import SEO from "../components/seo";
import BackgroundImage from "gatsby-background-image";
import { convertToBgImage } from "gbimage-bridge";
import Modal from "react-modal";
import { Fade } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { Script } from "gatsby";
import { Slider } from "react-typeform-embed";

const ReturnImage = (props) => {
  return <GatsbyImage image={props.propertyImage.asset.gatsbyImageData} />;
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Property = ({ data }) => {
  const property = data.sanityProperty;
  let bgImage = convertToBgImage(
    data.heroImage.childImageSharp.gatsbyImageData
  );
  const imgCount = property.images.length;
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  return (
    <Layout type="story-outer">
      <SEO title={property.headline} lang="en"></SEO>

      
      <Container>
        <div
          sx={{
            margin: ["40px 0px", "50px 0px"],
            display: "flex",
            justifyContent: "space-between",
            flexDirection: ["column", "row", "row"],
          }}
        >
          <div
            className="property-left"
            sx={{
              width: ["100%", "calc(100% - 240px)", "calc(100% - 340px)"],
              h1: {
                margin: "0px 0px 10px 0px",
                lineHeight: "1.2",
                fontSize:"34px",
              },
              h2: {
                fontSize: "24px",
                lineHeight: "1.2",
                margin: "0px 0px 10px 0px",
              },
              h3: {
                fontSize: "24px",
                marginBottom: "20px",
                display: "inline-block",
                borderBottom: "3px solid",
                borderColor: "primary",
              },
              li: {
                marginLeft: "16px",
              },
            }}
          >
            {property.headline && <h1>{property.headline}</h1>}
            {property.tagline && <h2>{property.tagline}</h2>}
            {property._rawIntroText && (
              <div>
                <div
                  sx={{
                    fontStyle: "italic",
                    marginBottom: "40px",
                  }}
                >
                  <PortableText
                    value={property._rawIntroText}
                    components={PortableTextComponent}
                  />
                </div>
              </div>
            )}
            <div
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: ["column", "row", "row"],
        }}
      >
        <div
          sx={{
            zIndex: "1",
            position: "absolute",
            top: "0",
            left: "0",
            height: "100%",
            width: "100%",
            opacity: "0.8",
            background:
              "linear-gradient(to top, transparent 0% , transparent 70%, #000000 83%, #000000 100%)",
            cursor: "pointer",
            pointerEvents: "none",
          }}
        ></div>
        <Modal isOpen={modalIsOpen}>
          <div
            onClick={closeModal}
            sx={{
              fontWeight: "bold",
              fontSize: "38px",
              position: "absolute",
              right: "10px",
              top: "0px",
              cursor: "pointer",
              zIndex: "9",
            }}
          >
            X
          </div>
          <Fade
            sx={{
              maxHeight: "90vh",
              textAlign: "center",
            }}
          >
            {property.images.map((image, index) => (
              <div
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <GatsbyImage image={image.asset.gatsbyImageData} />
              </div>
            ))}
          </Fade>
        </Modal>
        <div
          sx={{
            width: ["100%", "50%", "50%"],
            cursor: "pointer",
            position: "relative",
            "&:hover .image-overlay": {
              display: "block",
            },
          }}
          onClick={openModal}
        >
          <div
            className="image-overlay"
            sx={{
              zIndex: "1",
              position: "absolute",
              top: "0",
              left: "0",
              height: "100%",
              width: "100%",
              opacity: "0.2",
              background: "#ba101d",
              cursor: "pointer",
              pointerEvents: "none",
              display: "none",
            }}
          ></div>
          {property.images[0] && (
            <GatsbyImage image={property.images[0].asset.gatsbyImageData} />
          )}
        </div>
        <div
          sx={{
            display: "flex",
            flexWrap: "wrap",
            width: ["100%", "50%", "50%"],
            "> div": {
              width: "50%",
            },
          }}
        >
          {property.images.map(
            (propertyImage, index) =>
              propertyImage.asset &&
              index < 5 &&
              index !== 0 && (
                <div
                  sx={{
                    position: "relative",
                    cursor: "pointer",
                    "&:hover .image-overlay": {
                      display: "block",
                    },
                  }}
                  onClick={openModal}
                  className={"top-image-" + index}
                >
                  <div
                    className="image-overlay"
                    sx={{
                      zIndex: "1",
                      position: "absolute",
                      top: "0",
                      left: "0",
                      height: "100%",
                      width: "100%",
                      opacity: "0.2",
                      background: "#ba101d",
                      cursor: "pointer",
                      pointerEvents: "none",
                      display: "none",
                    }}
                  ></div>
                  <GatsbyImage image={propertyImage.asset.gatsbyImageData} />
                  {index == 4 && (
                    <div
                      sx={{
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        top: "0px",
                        left: "0px",
                        color: "white",
                      }}
                    >
                      <div
                        sx={{
                          fontSize: ["18px", "24px", "28px"],
                          lineHeight: "1.2",
                          textShadow: "2px 2px #000000",
                          padding: "10px",
                        }}
                      >
                        See all {imgCount} photos
                      </div>
                    </div>
                  )}
                </div>
              )
          )}
        </div>
      </div>
            {property._rawPropertyAmenities && (
              <div
                sx={{
                  paddingTop: "40px",
                  marginBottom: "40px",
                  borderTop: "thin solid",
                  borderColor: "gray",
                }}
              >
                <h3>Property Amenities</h3>
                <div
                  sx={{
                    ul: {
                      display: "flex",
                      padding: "0px",
                      margin: "0px",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      li: {
                        width: [
                          "100%",
                          "calc(100% / 2 - 40px)",
                          "calc(100% / 3 - 40px)",
                        ],
                      },
                    },
                  }}
                >
                  <PortableText
                    value={property._rawPropertyAmenities}
                    components={PortableTextComponent}
                  />
                </div>
              </div>
            )}
            {property._rawUnitAmenities && (
              <div
                sx={{
                  paddingTop: "40px",
                  marginBottom: "40px",
                  borderTop: "thin solid",
                  borderColor: "gray",
                }}
              >
                <h3>Unit Amenities</h3>
                <div
                  sx={{
                    ul: {
                      display: "flex",
                      padding: "0px",
                      margin: "0px",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      li: {
                        width: [
                          "100%",
                          "calc(100% / 2 - 40px)",
                          "calc(100% / 3 - 40px)",
                        ],
                      },
                    },
                  }}
                >
                  <PortableText
                    value={property._rawUnitAmenities}
                    components={PortableTextComponent}
                  />
                </div>
              </div>
            )}
            {property._rawStlchMenities && (
              <div
                sx={{
                  paddingTop: "40px",
                  marginBottom: "40px",
                  borderTop: "thin solid",
                  borderColor: "gray",
                }}
              >
                <h3>STLCH Amenities</h3>
                <div
                  sx={{
                    ul: {
                      display: "flex",
                      padding: "0px",
                      margin: "0px",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      li: {
                        width: [
                          "100%",
                          "calc(100% / 2 - 40px)",
                          "calc(100% / 3 - 40px)",
                        ],
                      },
                    },
                  }}
                >
                  <PortableText
                    value={property._rawStlchMenities}
                    components={PortableTextComponent}
                  />
                </div>
                <div
                sx={{
                  paddingTop: "40px",
                  marginTop: "40px",
                  borderTop: "thin solid",
                  borderColor: "#8e8d8f",
                }}
                >
                <div>'*In Select Units</div>
                <div><Link to="/what-we-include">See Everything That's Included</Link></div>
                </div>
              </div>
            )}
            {property._rawUnitTypes && (
              <div
                sx={{
                  paddingTop: "40px",
                  marginBottom: "40px",
                  borderTop: "thin solid",
                  borderColor: "gray",
                }}
              >
                <h3>Unit Types</h3>
                <div
                  sx={{
                    ul: {
                      display: "flex",
                      padding: "0px",
                      margin: "0px",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      li: {
                        width: "calc(100% / 3 - 40px)",
                      },
                    },
                  }}
                >
                  <PortableText
                    value={property._rawUnitTypes}
                    components={PortableTextComponent}
                  />
                </div>
              </div>
            )}
            {property._rawDocuments && (
              <div
                sx={{
                  paddingTop: "40px",
                  marginBottom: "40px",
                  borderTop: "thin solid",
                  borderColor: "gray",
                }}
              >
                <h3>Documents</h3>
                <div
                  sx={{
                    ul: {
                      display: "flex",
                      padding: "0px",
                      margin: "0px",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      li: {
                        width: "calc(100% / 3 - 40px)",
                      },
                    },
                  }}
                >
                  <PortableText
                    value={property._rawDocuments}
                    components={PortableTextComponent}
                  />
                </div>
              </div>
            )}
          </div>
          <div
            className="property-right"
            sx={{
              width: ["100%", "200px", "300px"],
              ".prop-right-cta": {
                variant: "buttons.primarySolid",
              },
            }}
          >
            <div
              sx={{
                position: "sticky",
                top: "160px",
                h4: {
                  margin: "0px",
                  fontSize: "22px",
                },
                h5: {
                  margin: "0px",
                  fontSize: "18px",
                },
              }}
            >
              {property.neighborhood && (
                <div>
                  {property.neighborhood.generalArea && (
                    <h4>{property.neighborhood.generalArea.headline}</h4>
                  )}
                  <h5>{property.neighborhood.headline}</h5>
                </div>
              )}
              {property.address && (
                
                <div
                  sx={{
                    marginTop: "5px",
                    marginBottom: "20px",
                    // whiteSpace: "nowrap",
                    overflow: "hidden",
                    // textOverflow: "clip",
                  }}
                >
                  {property.address}
                </div>
              )}
              <div
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <Link to="/get-started">
                  <button
                    sx={{
                      variant: "buttons.primarySolid",
                      border: "none",
                    }}
                  >
                    Get Started
                  </button>
                  </Link>
              </div>
              <div
                sx={{
                  marginTop: "20px",
                }}
              >
                We work with hundreds of properties in the area. If you like
                this property, tell us why and we may have others.
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Layout>
  );
};
export default Property;

export const PropertyQuery = graphql`
  query PropertyBySlug($slug: String!) {
    facebookIcon: file(name: { eq: "Facebook" }) {
      publicURL
    }
    twitterIcon: file(name: { eq: "Twitter" }) {
      publicURL
    }
    emailIcon: file(name: { eq: "Email" }) {
      publicURL
    }
    heroImage: file(name: { eq: "freedom" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    sanityProperty(slug: { current: { eq: $slug } }) {
      slug {
        current
      }
      headline
      tagline
      _rawPropertyAmenities(resolveReferences: { maxDepth: 10 })
      _rawStlchMenities(resolveReferences: { maxDepth: 10 })
      _rawUnitAmenities(resolveReferences: { maxDepth: 10 })
      _rawUnitTypes(resolveReferences: { maxDepth: 10 })
      _rawIntroText(resolveReferences: { maxDepth: 10 })
      _rawDocuments(resolveReferences: { maxDepth: 10 })
      address
      rating
      neighborhood {
        headline
        generalArea {
          headline
        }
      }
      main_image {
        asset {
          gatsbyImageData(width: 1000)
        }
      }
      images {
        asset {
          gatsbyImageData(
            width: 900
            height: 600
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            layout: CONSTRAINED
          )
        }
      }
    }
  }
`;
